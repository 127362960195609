import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

export const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navLinks = [
    { to: "/about-us", label: "About Us" },
    { to: "/private-investigation-services", label: "Services" },
    { to: "/client-testimonials", label: "Testimonials" },
    { to: "/faq", label: "FAQ" },
    { to: "/contact-us", label: "Contact Us" }
  ];

  const navLinkClasses = "flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-s font-bold uppercase duration-1000 transition-all text-gold hover:bg-gold/70 active:bg-dark-gold/40 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none";
  const mobileNavLinkClasses = "font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gold block w-full py-2 px-4 rounded hover:bg-gray-100";

  return (
    <nav className="relative z-50">
      <div className="px-4 py-5 mx-auto md:px-24 lg:px-8 bg-navy relative z-20">
        <div className="relative flex items-center justify-between">
          <Link
            to="/"
            aria-label="Company"
            title="The Kenzie Group"
            className="inline-flex items-center text-gold"
          >
            <img src={logo} alt="Company Logo" className="h-20 w-20 mr-2" />
            <span className="ml-2 text-xl font-bold tracking-wide text-gold uppercase">
              The Kenzie Group
            </span>
          </Link>

          <div className="p-6 text-gold border border-gold">
            <p>We Speak Romanian!</p>
            <p>Vorbim Românește!</p>
          </div>

          {/* Desktop Navigation */}
          <ul className="hidden lg:flex items-center space-x-8">
            {navLinks.map((link) => (
              <li key={link.to}>
                <Link
                  to={link.to}
                  aria-label={link.label}
                  title={link.label}
                  className={navLinkClasses}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>

          {/* Mobile Menu Button */}
          <div className="lg:hidden">
            <button
              aria-label={isMenuOpen ? "Close Menu" : "Open Menu"}
              title={isMenuOpen ? "Close Menu" : "Open Menu"}
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-gold/10 focus:bg-gold/10 text-gold"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {isMenuOpen ? (
                  <path d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <>
                    <path d="M3 12h18" />
                    <path d="M3 6h18" />
                    <path d="M3 18h18" />
                  </>
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-full left-0 w-full z-50 lg:hidden">
          <div className="p-5 bg-white border rounded shadow-sm">
            <ul className="space-y-4">
              {navLinks.map((link) => (
                <li key={link.to}>
                  <Link
                    to={link.to}
                    aria-label={link.label}
                    title={link.label}
                    className={mobileNavLinkClasses}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Decorative Wave */}
      <div className="relative z-30">
        <svg
          className="h-6 w-full text-gold"
          preserveAspectRatio="none"
          viewBox="0 0 1440 54"
          aria-hidden="true"
        >
          <path
            fill="currentColor"
            d="M1440 32L1320 37.3C1200 43 960 53 720 53.3C480 53 240 43 120 37.3L0 32V0H120C240 0 480 0 720 0C960 0 1200 0 1320 0H1440V32Z"
          />
        </svg>
      </div>
    </nav>
  );
};

export default Nav;
