// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './tailwind.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import TeamPage from './pages/TeamPage';
import ContactPage from './pages/ContactPage';
import TestimonialsPage from './pages/TestimonialsPage';
import FaqPage from './pages/FaqPage';
import ServicesPage from './pages/ServicesPage';
import Navbar from './components/layout/navbar';
import Footer from './components/layout/footer';
import TermsofService from './pages/ToSPage.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';

const App = () => {
  return (
    <div className='bg-off-white min-h-screen flex flex-col'>
      <Router>
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/client-testimonials" element={<TestimonialsPage />} />
            <Route path="/private-investigation-services" element={<ServicesPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/terms-of-service" element={<TermsofService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
