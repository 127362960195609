export const ContactForm = () => {
  return (
    <div className="max-w-5xl mx-auto mb-24">
      <div className="grid sm:grid-cols-2 gap-12 p-8 bg-navy/95 shadow-xl rounded-lg text-off-white backdrop-blur-sm">
        <div className="space-y-10">
          <div>
            <h2 className="text-2xl font-bold text-gold mb-4">Get in Touch</h2>
            <p className="text-off-white/90 leading-relaxed">
              If you're seeking answers or need discreet investigative services,
              we're here to help. Connect with us to schedule your private consultation.
              Your peace of mind is our priority.
            </p>
          </div>

          <div className="space-y-8">
            <h3 className="text-xl font-bold text-gold">Contact Information</h3>
            <ul className="space-y-8">
              <li className="flex items-center group">
                <div className="bg-navy h-12 w-12 rounded-full flex items-center justify-center shrink-0 transition-colors group-hover:bg-gold border border-gold/20">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    className="w-6 h-6 fill-current text-gold group-hover:text-navy"
                  >
                    <path d="M27.308,20.649l-2.2-2.2a3.521,3.521,0,0,0-4.938-.021,2.152,2.152,0,0,1-2.729.267A15.026,15.026,0,0,1,13.3,14.562a2.181,2.181,0,0,1,.284-2.739A3.521,3.521,0,0,0,13.553,6.9l-2.2-2.2a3.514,3.514,0,0,0-4.961,0l-.633.634c-3.3,3.3-3.053,10.238,3.813,17.1,4.14,4.141,8.307,5.875,11.686,5.875a7.5,7.5,0,0,0,5.418-2.061l.634-.634A3.513,3.513,0,0,0,27.308,20.649Z" />
                  </svg>
                </div>
                <div className="ml-4">
                  <p className="text-sm text-off-white/70">Phone</p>
                  <p className="text-gold font-semibold">(971) 357-5202</p>
                </div>
              </li>

              <li className="flex items-center group">
                <div className="bg-navy h-12 w-12 rounded-full flex items-center justify-center shrink-0 transition-colors group-hover:bg-gold border border-gold/20">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 fill-current text-gold group-hover:text-navy"
                  >
                    <path d="M12 2a8 8 0 0 0-8 7.92c0 5.48 7.05 11.58 7.35 11.84a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 9.92A8 8 0 0 0 12 2zm0 17.65c-1.67-1.59-6-6-6-9.73a6 6 0 0 1 12 0c0 3.7-4.33 8.14-6 9.73zm0-16.65a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 6zm0 5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 11z" />
                  </svg>
                </div>
                <div className="ml-4">
                  <p className="text-sm text-off-white/70">OR Address</p>
                  <p className="text-gold font-semibold">
                    330 NE Lincoln St. Suite 100<br />
                    Hillsboro, OR 97124
                  </p>
                </div>
              </li>

              <li className="flex items-center group">
                <div className="bg-navy h-12 w-12 rounded-full flex items-center justify-center shrink-0 transition-colors group-hover:bg-gold border border-gold/20">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 fill-current text-gold group-hover:text-navy"
                  >
                    <path d="M12 2a8 8 0 0 0-8 7.92c0 5.48 7.05 11.58 7.35 11.84a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 9.92A8 8 0 0 0 12 2zm0 17.65c-1.67-1.59-6-6-6-9.73a6 6 0 0 1 12 0c0 3.7-4.33 8.14-6 9.73zm0-16.65a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 6zm0 5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 11z" />
                  </svg>
                </div>
                <div className="ml-4">
                  <p className="text-sm text-off-white/70">CT Address</p>
                  <p className="text-gold font-semibold">
                    415 Boston Post Rd. Suite 3-1177<br />
                    Milford, CT 06460
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <form
          action="https://fabform.io/f/xxxxx"
          method="post"
          className="space-y-6"
        >
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="w-full rounded-md py-3 px-4 bg-navy border-2 border-gold/20 focus:border-gold text-off-white focus:text-charcoal focus:bg-light-navy placeholder-off-white/50 outline-none transition-colors"
            />
          </div>
          
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="w-full rounded-md py-3 px-4 bg-navy border-2 border-gold/20 focus:border-gold text-off-white focus:text-charcoal focus:bg-light-navy placeholder-off-white/50 outline-none transition-colors"
            />
          </div>
          
          <div>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              className="w-full rounded-md py-3 px-4 bg-navy border-2 border-gold/20 focus:border-gold text-off-white focus:text-charcoal focus:bg-light-navy placeholder-off-white/50 outline-none transition-colors"
            />
          </div>
          
          <div>
            <textarea
              name="message"
              placeholder="Message"
              rows="6"
              className="w-full rounded-md py-3 px-4 bg-navy border-2 border-gold/20 focus:border-gold text-off-white focus:text-charcoal focus:bg-light-navy placeholder-off-white/50 outline-none transition-colors resize-none"
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full py-3 px-6 rounded-md bg-dark-gold hover:bg-gold text-white font-semibold transition-colors duration-200 border-2 border-transparent hover:border-gold/20"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
