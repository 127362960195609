const teamData = [
    {
        id: 1,
        category: "Al McGee",
        bio: `Al became an Oregon-licensed private investigator in 2019. He is skilled in paying attention to details, leadership, report writing, and communication. He currently serves as a Private Security/Investigator Policy Committee member with the Oregon Department of Public Safety Standards and Training (DPSST).

Al was born in Riverside, California and raised in Compton and Long Beach. He began his career in 1986 when he earned his diploma in Law Enforcement and Security from the Metropolitan Technical Institute.

He began working in the Los Angeles area in the private sector, where he conducted a full range of investigative and protective services ranging from Loss Prevention, Plain Clothes Operations, Personal Protection, Insurance Fraud, and Corporate Investigations. In 1991, Al moved to Oregon, where he continued his career in the private sector, also earning an Associates degree in Criminal Justice. 

Al serves as the Squadron Soldier and Family Readiness Group Advisor/Coordinator for the Washington National Guard 1-303rd Cavalry Regiment. Al enjoys spending time with his family, cooking, hunting, and fishing, when he is not working.`,
        states: ["OR", "WA"],
        title: "Licensed Private Investigator"
    },
    {
        id: 2,
        category: "Chris LaMaine",
        bio: `Chris LaMaine brings unparalleled dedication and expertise to each case, backed by a distinguished career in law enforcement. His commitment to justice, meticulous investigative skills, and leadership make him a trusted partner in resolving even the most challenging investigations.

Chris spent 32 years with the Bridgeport Police Department, one of Connecticut's largest departments. During his career, Chris investigated more than 200 homicide cases and was praised for having one of the highest closure rates in the nation. He is credited with solving the oldest cold-case homicide in Bridgeport's history, along with solving the only cold-case homicide in Bridgeport's history before a body or any biological evidence was recovered. His career encompassed every area of criminal investigations. He spent 14 years overseeing more than 50 investigators, comprising more than a dozen specialized units, including the Homicide Unit, Robbery and Burglary Squad, Sexual Assault Unit, Domestic Violence Unit, Crime Scene Unit, Fugitive Unit, Financial Crimes Unit, Digital Video Recovery Team, and General Investigations Squads.

Chris is proficient in utilizing advanced investigative techniques and technology to gather actionable intelligence. He spent 8 years as the commander of a Narcotics Unit that was responsible for making over 9,600 arrests in that time. He has worked as an undercover officer in over 100 cases and spent years training others to work undercover. He possesses strong communication skills and the ability to present findings persuasively in both written reports and courtroom settings. He has testified in more than 200 criminal cases (State and Federal).

Chris has received many awards, including the Courage of Connecticut Award, the Bridgeport Police Department's Medal of Valor (Bridgeport's highest honor), and 2 United States Attorney's Awards (2010 & 2022).`,
        states: ["CT"],
        title: "Licensed Private Investigator"
    },
    {
        id: 3,
        category: "Cristina Lougal",
        bio: "Bio Coming Soon",
        states: ["OR", "CT"],
        title: "Licensed Private Investigator"
    },
    {
        id: 4,
        category: "Steven Lougal",
        bio: "Bio Coming Soon",
        states: ["OR", "CT"],
        title: "Licensed Private Investigator"
    },
    {
        id: 5,
        category: "Gil Del Valle",
        bio: "Bio Coming Soon",
        states: ["CT"],
        title: "Licensed Private Investigator"
    }
];

export default teamData;
