import React from 'react';
import BasicCard from '../components/common/BasicCard';
import mtjeff from '../assets/mt-jefferson.jpg';
import teamData from '../data/team';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-off-white">
      <div 
        className="relative py-16"
        style={{
          position: 'relative',
          zIndex: '10',
          marginTop: '-6rem', // Offset for the header wave
          paddingTop: 'calc(6rem + 4rem)', // Add back the offset plus some padding
          marginBottom: '-6rem', // Offset for the footer wave
          paddingBottom: 'calc(6rem + 4rem)', // Add back the offset plus some padding
          backgroundImage: `url(${mtjeff})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-navy/70"></div>
        
        <div className="relative z-10 max-w-7xl mx-auto px-4">
          <h1 className="text-5xl font-bold text-gold text-center mb-12">
            Our Team
          </h1>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {teamData.map((member) => (
              <BasicCard
                key={member.id}
                category={member.title}
                title={member.category}
                content={member.states.map(state => `Licensed in ${state}`).join(' • ')}
                link={`/team?member=${member.id}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
