import React, { useState } from 'react';
import testimonialsData from '../../data/testimonials';

const TestimonialCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === testimonialsData.length - 1;

  const goToNext = () => {
    if (!isNextDisabled) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const goToPrev = () => {
    if (!isPrevDisabled) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  const activeTestimonial = testimonialsData[activeIndex];

  return (
    <div className="carousel-container flex flex-col items-center justify-center relative max-w-4xl mx-auto">
      <div className="w-full mb-8">
        <div className="bg-forest-green/90 backdrop-blur-sm rounded-xl border border-gold/20 shadow-xl overflow-hidden transition-all duration-500 ease-in-out transform hover:shadow-2xl">
          <div className="p-10 md:p-12">
            <div className="flex flex-col items-center mb-8">
              <h3 className="text-3xl font-bold text-gold mb-3">{activeTestimonial.author}</h3>
              <span className="text-lg text-gold/80 font-medium">{activeTestimonial.descriptor}</span>
            </div>
            
            <div className="relative">
              <svg className="absolute top-0 left-0 transform -translate-x-6 -translate-y-6 h-12 w-12 text-gold/20" fill="currentColor" viewBox="0 0 32 32">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <blockquote className="text-off-white/90 text-xl leading-relaxed px-4 italic">
                {activeTestimonial.quote}
              </blockquote>
              <svg className="absolute bottom-0 right-0 transform translate-x-6 translate-y-6 h-12 w-12 text-gold/20" fill="currentColor" viewBox="0 0 32 32">
                <path d="M22.648 28C27.544 24.544 31 18.88 31 12.64c0-5.088-3.072-8.064-6.624-8.064-3.36 0-5.856 2.688-5.856 5.856 0 3.168 2.208 5.472 5.088 5.472.576 0 1.344-.096 1.536-.192-.48 3.264-3.552 7.104-6.624 9.024L22.648 28zm-16.512 0c4.8-3.456 8.256-9.12 8.256-15.36 0-5.088-3.072-8.064-6.624-8.064-3.264 0-5.856 2.688-5.856 5.856 0 3.168 2.304 5.472 5.184 5.472.576 0 1.248-.096 1.44-.192-.48 3.264-3.456 7.104-6.528 9.024L6.136 28z" />
              </svg>
            </div>

            {activeTestimonial.link && (
              <div className="text-center mt-10">
                <a 
                  href={activeTestimonial.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-navy hover:bg-navy/80 text-gold font-bold py-3 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
                >
                  Read Full Story
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Navigation Controls */}
      <div className="w-full flex flex-col items-center gap-8 bg-navy/30 backdrop-blur-sm rounded-xl p-8 border border-gold/20">
        <div className="text-center">
          <span className="text-gold/80 text-lg font-medium">
            {activeIndex + 1} of {testimonialsData.length}
          </span>
        </div>

        <div className="flex items-center justify-center gap-6">
          <button 
            onClick={goToPrev}
            disabled={isPrevDisabled}
            className={`bg-navy hover:bg-navy/80 text-gold font-bold py-3 px-8 rounded-lg transition-all duration-300 flex items-center ${
              isPrevDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 hover:shadow-lg'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
            Previous
          </button>
          
          <div className="flex justify-center items-center gap-3">
            {testimonialsData.map((_, index) => (
              <button
                key={index}
                aria-label={`Go to testimonial ${index + 1}`}
                className={`w-3 h-3 rounded-full transition-all duration-300 border-2 border-gold ${
                  activeIndex === index 
                    ? 'bg-gold scale-125' 
                    : 'bg-transparent hover:bg-gold/50'
                }`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>

          <button 
            onClick={goToNext}
            disabled={isNextDisabled}
            className={`bg-navy hover:bg-navy/80 text-gold font-bold py-3 px-8 rounded-lg transition-all duration-300 flex items-center ${
              isNextDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 hover:shadow-lg'
            }`}
          >
            Next
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCarousel;
