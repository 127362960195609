// pages/TeamPage.jsx

import React, { useState } from 'react';
import teamData from '../data/team';
import BasicCard from '../components/common/BasicCard';
import portlandbg from '../assets/cityscape-and-skyline-of-portland-oregon_800.jpg';
import CollapsibleSidebar from '../components/common/CollapsibleSidebar';

const TeamPage = () => {
    const [selectedTeam, setSelectedTeam] = useState(teamData[0]);

    return (
        <div className="min-h-screen relative">
            {/* Background Image with Overlay */}
            <div 
                className="fixed inset-0 z-0"
                style={{
                    backgroundImage: `url(${portlandbg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="absolute inset-0 bg-navy/70"></div>
            </div>

            {/* Content */}
            <div className="relative z-10 flex flex-col lg:flex-row min-h-screen pt-20 pb-12 px-4 lg:pt-24 lg:pb-24">
                {/* Sidebar Container */}
                <div className="w-full lg:w-80 lg:flex-shrink-0 mb-6 lg:mb-0">
                    <CollapsibleSidebar 
                        items={teamData}
                        selectedItem={selectedTeam}
                        setSelectedItem={setSelectedTeam}
                    />
                </div>

                {/* Main Content */}
                <div className="flex-1 lg:p-8">
                    <div className="max-w-4xl mx-auto bg-forest-green/95 rounded-lg shadow-xl overflow-hidden">
                        {/* Header */}
                        <div className="bg-navy/80 p-4 lg:p-6 text-center">
                            <h1 className="text-3xl lg:text-4xl font-bold text-gold mb-2">
                                {selectedTeam.category}
                            </h1>
                            <h2 className="text-lg lg:text-xl text-gold/80 mb-4">
                                {selectedTeam.title}
                            </h2>
                            <div className="flex flex-wrap justify-center gap-2 lg:gap-4">
                                {selectedTeam.states.map((state) => (
                                    <span 
                                        key={state}
                                        className="px-3 py-1 bg-gold/20 text-gold rounded-full text-sm font-semibold"
                                    >
                                        Licensed in {state}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {/* Bio Content */}
                        <div className="p-4 lg:p-8">
                            <div className="prose prose-lg prose-invert max-w-none">
                                {selectedTeam.bio.split('\n\n').map((paragraph, index) => (
                                    <p key={index} className="text-off-white text-base lg:text-lg leading-relaxed mb-4 lg:mb-6">
                                        {paragraph}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamPage;
