import React from 'react';
import TestimonialsCarousel from '../components/common/TestimonialCarousel';
import portlandbg from '../assets/cityscape-and-skyline-of-portland-oregon_800.jpg';

const TestimonialsPage = () => {
  return (
    <div className="min-h-screen bg-off-white">
      <div 
        className="relative py-16"
        style={{
          position: 'relative',
          zIndex: '10',
          marginTop: '-6rem',
          paddingTop: 'calc(6rem + 4rem)',
          marginBottom: '-6rem',
          paddingBottom: 'calc(6rem + 4rem)',
          backgroundImage: `url(${portlandbg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-navy/70"></div>
        
        <div className="relative z-10 container mx-auto px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h1 className="text-5xl font-bold text-gold mb-6">
              Client Testimonials
            </h1>
            <p className="text-lg text-off-white/90">
              Hear what our clients have to say about their experience working with The Kenzie Group
            </p>
          </div>

          <div className="max-w-4xl mx-auto">
            <TestimonialsCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsPage;
