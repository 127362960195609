import React, { useState } from "react";

const CollapsibleSidebar = ({ items, selectedItem, setSelectedItem }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (window.innerWidth <= 768) {
      setIsCollapsed(true);
    }
  };

  return (
    <div
      className={`sticky top-24 h-[calc(100vh-12rem)] ${
        isCollapsed ? "w-16" : "w-80"
      } transition-all duration-300 ease-in-out z-10`}
    >
      <button
        onClick={toggleSidebar}
        className="absolute -right-4 top-4 p-2 bg-navy text-gold rounded-full shadow-lg z-20 hover:bg-gold hover:text-navy transition-colors"
        aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        {isCollapsed ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        )}
      </button>
      <div
        className={`${
          isCollapsed ? "hidden" : "block"
        } bg-forest-green shadow-lg p-6 rounded-r-lg h-full overflow-y-auto`}
      >
        <ul className="space-y-4">
          {items?.map((item) => (
            <li
              key={item.id}
              className={`cursor-pointer p-4 text-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${
                selectedItem?.id === item.id
                  ? "bg-gold text-charcoal font-bold shadow-md"
                  : "hover:text-charcoal hover:bg-gold/70 hover:shadow-lg text-dark-gold"
              }`}
              onClick={() => handleItemClick(item)}
            >
              <h3 className="text-lg font-semibold mb-1">{item.category}</h3>
              {item.title && <p className="text-sm opacity-75">{item.title}</p>}
              {item.states && (
                <div className="mt-2 flex gap-2 justify-center">
                  {item.states.map((state) => (
                    <span
                      key={state}
                      className="text-xs px-2 py-1 bg-navy/20 rounded-full"
                    >
                      {state}
                    </span>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CollapsibleSidebar;
