import React from 'react';
import PropTypes from 'prop-types';

const BasicCard = ({ category, title, content, imageUrl, link, imageAlt, className = '' }) => {
  const displayTitle = title || category;

  return (
    <div className={`transform transition-all duration-300 hover:scale-105 ${className}`}>
      <div className="relative flex flex-col rounded-xl bg-forest-green/90 border border-gold/20 shadow-lg backdrop-blur-sm hover:shadow-xl transition-all duration-300">
        <div className="flex flex-col md:flex-row items-center p-6">
          {imageUrl && (
            <div className="w-full md:w-auto shrink-0 h-[100px] bg-white rounded-lg overflow-hidden">
              <img 
                src={imageUrl} 
                alt={imageAlt || `${displayTitle} illustration`} 
                className="h-full w-full object-cover" 
              />
            </div>
          )}
          <div className={`${imageUrl ? 'md:pl-6' : ''} text-center flex flex-col w-full`}>
            {category && title && (
              <span className="text-base font-bold text-gold/80 p-2 text-center">
                {category}
              </span>
            )}
            <h2 className="text-2xl font-bold text-gold p-2 text-center">
              {displayTitle}
            </h2>
          </div>
        </div>
        <div className="px-8 py-6 flex-1">
          <p className="text-lg text-off-white leading-relaxed">{content}</p>
        </div>
        {link && (
          <div className="p-6 text-center border-t border-gold/20">
            <a 
              href={link}
              className="inline-block bg-gold text-navy font-bold uppercase px-6 py-2 rounded-lg hover:bg-dark-gold transition-colors duration-200"
              aria-label={`Read more about ${displayTitle}`}
            >
              Read More
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

BasicCard.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  link: PropTypes.string,
  imageAlt: PropTypes.string,
  className: PropTypes.string
};

export default BasicCard;
