import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import Button from "../common/BasicButton";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";
import ndia from "../../assets/ndia-logo.jpg";
import oali from "../../assets/oali-logo.jpg";
import ocdla from "../../assets/ocdla-logo.png"

const ContactItem = ({ icon, details }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex-shrink-0">{icon}</div>
      <div className="text-sm">{details}</div>
    </div>
  );
};

export const Footer = () => {
  return (
    <footer className="relative z-50 mt-32">
      <div className="relative z-30">
        <svg
          className="absolute bottom-full w-full h-6 sm:h-16 text-dark-gold"
          preserveAspectRatio="none"
          viewBox="0 0 1440 54"
          aria-hidden="true"
        >
          <path
            fill="currentColor"
            d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
          />
        </svg>
      </div>
      <div className="relative z-20 bg-navy">
        <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          {/* Footer Body */}
          <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6 text-dark-gold">
            <div className="md:max-w-md lg:col-span-2">
              <Link
                to="/"
                aria-label="Go home"
                title="Company"
                className="inline-flex items-center"
              >
                <img
                  src={logo}
                  alt="Company Logo"
                  className="w-16"
                />
                <span className="ml-2 text-xl font-bold tracking-wide text-gold uppercase">
                  The Kenzie Group
                </span>
              </Link>
              <div className="mt-4 lg:max-w-sm">
                <p className="text-sm">
                  Our mission is to provide our clients with meticulous and
                  trustworthy legal support services. We believe in due process,
                  transparency, and the right to a robust defense. With every
                  case, we strive to ensure that justice is not just a principle,
                  but a reality for our clients.
                </p>
              </div>
            </div>
            {/* Footer Nav */}
            <div className="grid grid-cols-1 gap-3 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div>
                <p className="font-semibold tracking-wide">Contact</p>
                <ul className="mt-2 space-y-2 text-old-text">
                  <li>
                    <ContactItem
                      icon={<PhoneIcon className="h-4 w-4" />}
                      details="(971) 357-5202"
                    />
                  </li>
                  <li>
                    <a 
                      href="mailto:office@thekenziegroup.com" 
                      className="hover:text-burgundy hover:underline inline-flex"
                    >
                      <ContactItem
                        icon={<EnvelopeIcon className="h-4 w-4" />}
                        details="office@thekenziegroup.com"
                      />
                    </a>
                  </li>
                  <li>
                    <ContactItem
                      icon={<MapPinIcon className="h-4 w-4" />}
                      details="330 NE Lincoln St. Suite 100 Hillsboro, OR 97124"
                    />
                  </li>
                  <li>
                    <ContactItem
                      icon={<MapPinIcon className="h-4 w-4" />}
                      details="415 Boston Post Rd. Suite 3-1177 Milford, CT 06460"
                    />
                  </li>
                  <li>
                    <Button
                      label="Consultation Request"
                      href="/contact-us"
                    />
                  </li>
                </ul>
              </div>
              <div className="pb-4 lg:pl-8">
                <p className="font-semibold tracking-wide">Navigation</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link
                      to="/about-us"
                      className="transition-colors duration-300 text-gold hover:text-burgundy"
                    >
                      Our Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/client-testimonials"
                      className="transition-colors duration-300 text-gold hover:text-burgundy"
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/private-investigation-services"
                      className="transition-colors duration-300 text-gold hover:text-burgundy"
                    >
                      Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide">Legal</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link
                      to="/terms-of-service"
                      className="transition-colors duration-300 text-gold hover:text-burgundy"
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      className="transition-colors duration-300 text-gold hover:text-burgundy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col items-center justify-center">
                <img 
                  src={oali} 
                  alt="Oregon Association of Licensed Investigators" 
                  className="w-[100px] mb-2" 
                />
                <img 
                  src={ndia} 
                  alt="National Defender Investigator Association" 
                  className="w-[150px] mb-2" 
                />
                <img 
                  src={ocdla} 
                  alt="Oregon Criminal Defense Lawyers Association" 
                  className="w-[80px] h-[120px] bg-white" 
                />
              </div>
            </div>
          </div>

          {/* Copyright and Social Media Links */}
          <div className="flex flex-col justify-between pt-5 pb-10 border-t border-off-white sm:flex-row">
            <p className="text-sm text-off-white">
              © Copyright {new Date().getFullYear()} The Kenzie Group. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
