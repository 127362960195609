import React from 'react';
import ContactForm from '../components/layout/ContactForm';
import portland from '../assets/Portland-David-Papazian-Getty-Images.webp';

const ContactPage = () => {
  return (
    <div className="min-h-screen bg-off-white">
      <div 
        className="relative py-16"
        style={{
          position: 'relative',
          zIndex: '10',
          marginTop: '-6rem',
          paddingTop: 'calc(6rem + 4rem)',
          marginBottom: '-6rem',
          paddingBottom: 'calc(6rem + 4rem)',
          backgroundImage: `url(${portland})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-navy/80"></div>
        
        <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h1 className="text-4xl sm:text-5xl font-bold text-gold mb-4">
              Contact Us
            </h1>
            <p className="text-lg text-off-white/90">
              Ready to get started? Reach out to us for a confidential consultation.
            </p>
          </div>

          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
