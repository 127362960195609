import React from 'react';
import BasicCard from '../components/common/BasicCard';
import portland from '../assets/Portland-David-Papazian-Getty-Images.webp';

const servicesData = [
  {
    id: 1,
    category: "Background Checks",
    content: "Our comprehensive background checks offer peace of mind for employers, landlords, and individuals seeking in-depth information about individuals' history and credibility. We utilize the latest databases and research techniques to ensure accurate, discreet, and timely reports. Our reports cover criminal records, employment history, education verification, and more, supporting informed decisions."
  },
  {
    id: 2,
    category: "Surveillance",
    content: "Specializing in discreet and continuous surveillance, our experienced team employs state-of-the-art technology to gather crucial information for personal, legal, or corporate matters. Whether it's monitoring a subject's movements, uncovering unauthorized activities, or collecting evidence for a case, our surveillance services are designed to meet your specific objectives with utmost confidentiality."
  },
  {
    id: 3,
    category: "Legal Investigations",
    content: "Tailored to support attorneys and legal professionals, our legal investigations provide thorough research, evidence collection, and witness interviews to build a compelling case. From civil disputes to criminal defense, our expert investigators work diligently to uncover the truth, ensuring you have the critical information needed for litigation or negotiation."
  },
  {
    id: 4,
    category: "Corporate Investigations",
    content: "Protect your business interests with our specialized corporate investigations. Addressing issues such as internal fraud, intellectual property theft, compliance violations, and due diligence, we help corporations mitigate risks, secure assets, and maintain their reputation through strategic investigation and analysis."
  },
  {
    id: 5,
    category: "Personal Investigations",
    content: "Catering to private individuals, our personal investigation services discreetly address sensitive issues such as missing persons, infidelity, and personal security threats. With empathy and professionalism, we provide clarity and closure on personal matters, offering peace of mind through reliable investigation results."
  },
  {
    id: 6,
    category: "Case Records Research",
    content: "Specializing in the meticulous examination of civil and criminal records, our service supports legal professionals and individuals by uncovering vital information for cases. Our detailed research and analysis facilitate strategy development, offering a comprehensive view of the legal landscape pertinent to your case."
  },
  {
    id: 7,
    category: "Mobile Notary",
    content: "Our mobile notary service offers convenient, on-site notarization of documents, ensuring their legal validity. Ideal for legal documents, real estate transactions, and important contracts, we provide certified notary public services at your location, accommodating busy schedules and urgent needs with professionalism and reliability."
  },
  {
    id: 8,
    category: "Social Media Investigations",
    content: "In the digital age, social media platforms are a rich source of personal information and evidence. Our social media investigations delve into online profiles, activities, and interactions to uncover behaviors, connections, or evidence relevant to personal, legal, or corporate matters, providing a comprehensive digital footprint analysis."
  }
];

const ServicesPage = () => {
  return (
    <div className="min-h-screen bg-off-white">
      <div 
        className="relative py-16"
        style={{
          position: 'relative',
          zIndex: '10',
          marginTop: '-6rem',
          paddingTop: 'calc(6rem + 4rem)',
          marginBottom: '-6rem',
          paddingBottom: 'calc(6rem + 4rem)',
          backgroundImage: `url(${portland})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="absolute inset-0 bg-navy/70"></div>
        
        <div className="relative z-10 container mx-auto px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h1 className="text-5xl font-bold text-gold mb-6">
              Private Investigation Services
            </h1>
            <p className="text-lg text-off-white/90">
              Professional and discreet investigative services tailored to meet your specific needs
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {servicesData.map((service) => (
              <BasicCard
                key={service.id}
                category={service.category}
                content={service.content}
                className="h-full"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
